@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: 'Bahnschrift';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "Ginchiest";
  src: local("Ginchiest"),
    url("./fonts/Ginchiest.woff") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "America";
  src: local("America"),
    url("./fonts/American-Captain.woff") format("truetype");
}

@font-face {
  font-family: "Bahnschrift";
  src: local("Bahnschrift"),
    url("./fonts/BAHNSCHRIFT.woff") format("truetype");
}


.bg-image-blur {
  background-size: cover;
  background-color: rgb(255, 255, 255);
  background-position: center center;
  opacity: 0.3;
  filter: blur(8px);
  -webkit-mask: linear-gradient(rgb(255, 255, 255), transparent);
}

.left-50 {
  left: calc(50% - 58px);
}

.top-50 {
  top: calc(50% - 58px);
}

.wheel > div {
  max-width: 450px !important;
  max-height: 450px !important;
}


.ginchiest {
  font-family: 'Ginchiest';
}

.america {
  font-family: 'America';
}

.bahnschrift {
  font-family: 'Bahnschrift';
}

.MuiTypography-root {
  color: white;
}

.MuiButton-label {
  color: white;
}

.MuiButton-containedPrimary{
  background-color: white !important;
}

.MuiButton-label {
  color: black !important;
  font-family: 'Bahnschrift' !important;
  text-transform: none !important;
}

.MuiButton-iconSizeMedium  {
  display: none !important;
}

.MuiList-root {
  background-color: white !important;
}
.MuiListItem-root{
  box-shadow: inset 0 1px 0 0 rgb(0 0 0 / 10%) !important;
}

.footer-logo {
  color: #C13540;
  display: inline-block;
  font-size: 45px;
  line-height: 36px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  letter-spacing: -1px;
  max-width: 100px;
  font-weight: bold;
  font-family: 'America';
  text-align: center;
}

.mask {
  mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
}

a {
  color: white !important;
}


.spin-title {
  letter-spacing: 2px;
  -webkit-text-stroke: 0.5px #ccc;
  text-shadow: 0 1px 0 #ccc,
               0 2px 0 #c9c9c9,
               0 3px 0 #bbb,
               0 4px 0 #b9b9b9,
               0 5px 0 #aaa,
               0 6px 1px rgba(0,0,0,.1),
               0 0 5px rgba(0,0,0,.1),
               0 1px 3px rgba(0,0,0,.3),
               0 3px 5px rgba(0,0,0,.2),
               0 5px 10px rgba(0,0,0,.25),
               0 10px 10px rgba(0,0,0,.2),
               0 20px 20px rgba(0,0,0,.15);
}

.bg-wheel {
  background: url("/public/basc/wheel-bg.png"), #bbae1d;
  background-size: contain;
  border-radius: 50%;
  border: 8px solid #dad9d9;
  background-repeat: no-repeat;
  position: relative;
  background-size: 500px 500px;
  background-position: center;
}

.outer-bg {
  background: url("/public/basc/lights.png"), #2c1111;
  background-size: contain;
  background-repeat: no-repeat;
  border: 8px solid #807367;
  border-radius: 50%;
  position: relative;
  background-size: 570px 580px;
  background-position: center;
}

@media only screen and (max-width: 800px) {
  .outer-bg {
    background: unset;
    background-size: unset;;
    border-radius: unset;
    border: unset;
    background-repeat: unset;
    position: unset;
    background-position: unset;
  }

  .bg-wheel {
    background: unset;
    background-size: unset;;
    border-radius: unset;
    border: unset;
    background-repeat: unset;
    position: unset;
    background-position: unset;
  }


  .left-50 {
    left: calc(50% - 34px);
  }
  
  .top-50 {
    top: calc(50% - 35px);
  }
  
}